<template>
  <v-app>
    <v-main class="fondo-login">
      <v-container>
        <v-row class="mt-15 justify-center">
          <v-col cols="12" xl="4" lg="4" md="6" sm="12">
            <v-card class="border-login">
              <v-card-text>
                <v-row>
                  <v-col cols="12 text-center" v-show="!mostrarContenido">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      :size="100"
                      :width="7"
                    ></v-progress-circular>
                  </v-col>
                </v-row>

                <v-form
                  @submit.prevent="autenticar"
                  v-show="mostrarContenido"
                  :disabled="disabledForm"
                >
                  <v-row>
                    <v-col cols="12">
                      <v-img
                        class="mx-auto"
                        contain
                        width="300"
                        height="160"
                        :src="empresa.logo"
                      >
                      </v-img>
                    </v-col>
                    <v-col cols="12">
                      <v-alert
                        class="text-left"
                        type="success"
                        v-model="alertExito"
                        dismissible
                      >
                        <p class="my-0" v-text="msjRespuesta"></p>
                      </v-alert>
                      <v-alert
                        class="text-left"
                        type="error"
                        v-model="alertError"
                        dismissible
                      >
                        <p v-text="msjRespuesta" class="my-0"></p>
                        <ul
                          class="my-0"
                          v-for="(errors, index) in listErrors"
                          :key="index"
                        >
                          <li v-text="errors[0]"></li>
                        </ul>
                      </v-alert>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="login.usuario"
                        label="Usuario"
                        prepend-icon="mdi-account-lock"
                        type="text"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="login.clave"
                        label="Contraseña"
                        prepend-icon="mdi-lock-question"
                        :append-icon="mostrarClave ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="mostrarClave ? 'text' : 'password'"
                        @click:append="mostrarClave = !mostrarClave"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" class="text-center">
                      <v-btn :loading="disabledForm" type="submit" color="success">
                        <v-icon left="">mdi-login</v-icon> Ingresar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      login: {
        usuario: "",
        clave: "",
      },
      empresa: {
        logo: "",
      },
      mostrarClave: false,
      msjRespuesta: "",
      listErrors: [],
      disabledForm: false,
      alertExito: false,
      alertError: false,
      mostrarContenido: false,
    };
  },

  methods: {
    getDatos() {
      this.axios({
        method: "POST",
        url: "api/panel/login/index",
      })
        .then((response) => {
          if (response.status === 200) {
            let empresa = response.data.data.empresa;
            if (empresa.logo != null) {
              this.empresa.logo = this.assetAWS("perfil_tienda/logo/" + empresa.logo);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          this.mostrarContenido = true;
        });
    },

    autenticar() {
      this.listErrors = [];
      this.disabledForm = true;
      this.alertError = false;
      this.alertExito = false;
      this.axios
        .post("api/panel/login/autenticar", this.login)
        .then((res) => {
          this.alertExito = true;
          this.msjRespuesta = res.data.mensaje;
          this.$session.set("token", res.data.token);
          this.$session.set("usuario", res.data.usuario);
          /* this.$router.push({
            name: "inicioPanel",
          });*/
          this.getRolesPermisos();
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.alertError = true;
            this.listErrors = Object.values(err.response.data.errors);
          } else if (err.response.status === 400) {
            this.alertError = true;
            this.msjRespuesta = err.response.data.mensaje;
          }
        })
        .then(() => {
          this.disabledForm = false;
        });
    },

    getRolesPermisos() {
      this.axios
        .post("api/panel/login/getRolesPermisos")
        .then((res) => {
          let jsPermissions = res.data.jsPermissions;
          this.$session.set("jsPermissions", jsPermissions);
        })
        .catch((err) => {
          console.error(err.response);
        })
        .then(() => {
          location.reload();
        });
    },
  },
  created() {
    this.getDatos();
  },
};
</script>

<style scoped>
.border-login {
  border: 1px solid rgba(35, 201, 243, 0.884);
}

.fondo-login {
  background-image: url("https://prooptimania.s3.us-east-2.amazonaws.com/ckfinder/images/optica-local-medida-de-vista-receta.jpg");
  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
